html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dark-text {
  color: #1e3547;
}

.main-text {
  font-size: 3rem;
  line-height: 4rem;
}

.menu-text{
  font-size: 1rem;
  font-weight: bold;
}
.small-text {
  font-size: 0.8rem;
}
.title-text {
  font-size: 2rem;
}
.subtitle-text {
  font-size: 1.5rem;
}
.text-gray{
  color: #a9a8a7;
}

@media (max-width:1399px) and (min-width:900px) {
  .main-text {
    font-size: 4rem;
    line-height: 4rem;
  }
  .menu-text{
    font-size: 1.2rem;
    font-weight: bold;
  }

}

@media only screen and (min-width: 1400px) {
  .main-text {
    font-size: 6vw;
    line-height: 6vw;
  }
  .menu-text{
    font-size: 1.2rem;
    font-weight: bold;
  }
  .text-float-container{
  position: absolute;
  left: 15vw;
  top:40vh;
}
.title-text {
  font-size: 4rem;
  position: relative;
}
.title-text:before {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background: -webkit-gradient(linear,left top,right top,from(#f5a441),color-stop(68.33%,rgba(134,126,255,.1)));
  background: -webkit-linear-gradient(left,#f5a441,rgba(134,126,255,.1) 68.33%);
  background: linear-gradient(90deg,#f5a441,rgba(134,126,255,.1) 68.33%);
  border-radius: 10px;
  bottom: 0;
  content: "";
}
.subtitle-text {
  font-size: 2.5rem;
}

.text-gray{
  font-size: 1.5rem;
}

}